<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
function resizeBaseSize() {
  let height = 1080 || document.body.clientHeight;
  document.querySelector("html").style.fontSize = (height * 62.5) / 1080 + "%";
}

// zoom缩放
const zoom = () => {
  let domBody = document.getElementsByTagName('body')[0]
  domBody.style.zoom = document.body.clientWidth / 19.2 + '%'
}
zoom()
// 窗口大小变化时，需要重算
window.addEventListener('resize', zoom, false)
resizeBaseSize();
</script>
<style lang="scss">
html,
body {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    list-style: none;
  }

  margin: 0;
  padding: 0 !important;
  transform-origin: 0 0 0;
  /*指定缩放的基本点*/
  -moz-transform-origin: 0 0 0;
  -ms-transform-origin: 0 0 0;
  -webkit-transform-origin: 0 0 0;
  -o-transform-origin: 0 0 0;
  background-color: #052948;
  overflow: hidden;
}

* {
  position: relative;
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
}

.el-dialog {
  background-color: #032043 !important;
  box-shadow: inset 0 0 20px rgb(32, 152, 214) !important;
  position: relative;

  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    display: block;
    width: 25px;
    height: 25px;
    border-bottom: 2px solid rgba(112, 184, 217, 1);
    border-left: 2px solid rgba(112, 184, 217, 1);
  }

  &::after {
    position: absolute;
    bottom: 0;
    right: 0;
    content: "";
    display: block;
    width: 25px;
    height: 25px;
    border-bottom: 2px solid rgba(112, 184, 217, 1);
    border-right: 2px solid rgba(112, 184, 217, 1);
  }

  .el-dialog__header {
    padding: 0 !important;
    height: 48px;
    line-height: 48px;
    background: url(@/assets/titlebg.png);
    background-size: 100% 100%;
    position: relative;
    text-align: center;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      display: block;
      width: 25px;
      height: 25px;
      border-top: 2px solid rgba(112, 184, 217, 1);
      border-left: 2px solid rgba(112, 184, 217, 1);
    }

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      content: "";
      display: block;
      width: 25px;
      height: 25px;
      border-top: 2px solid rgba(112, 184, 217, 1);
      border-right: 2px solid rgba(112, 184, 217, 1);
    }
    .el-dialog__title{
      line-height: unset;
    }
    span {
      color: rgb(255, 255, 255);
      font-size: 22px;
    }

    .el-dialog__headerbtn {
      top: 15px;

      .el-dialog__close {
        font-size: 22px;
        color: #70b8d9 !important;
      }
    }
  }

  .el-dialog__body {
    padding: 24px 18px !important;
  }

  .el-tabs--border-card {
    background: transparent;
    border: none;
    box-shadow: none;

    .el-tabs__header {
      background: transparent;
      border: none;

      .el-tabs__nav-scroll {
        display: flex;
        justify-content: center;
      }

      .el-tabs__item {
        width: 130px;
        height: 42px;
        line-height: 42px;
        border-radius: 4px;
        color: #3eaae2;
        padding: 0 !important;
        text-align: center;
        border: none;
        margin: 0 10px !important;
        box-shadow: inset 0 0 10px rgb(32, 152, 214);
      }

      .el-tabs__item.is-active {
        color: #fff !important;
        background: linear-gradient(0deg, rgb(21, 104, 149) 0%, rgb(32, 152, 214) 100%);
      }
    }

    .el-tabs__content {
      padding: 0 !important;
    }
  }
}

#nntable {
  background-color: transparent;
  border-color: #144a77;

  th.el-table__cell {
    background-color: #06294f;
  }

  td.el-table__cell, th.el-table__cell.is-leaf {
    border-color: #144a77;
  }

  th.el-table__cell > .cell {
    font-size: 16px;
    font-weight: 500;
    color: #78cbf9;
  }

  .cell {
    color: #ffffff;
    text-align: center;
  }

  tr {
    background-color: #032043;
  }

  .el-table__row--striped td.el-table__cell {
    background-color: #06294f;
  }
  tr:hover>td.el-table__cell{
    background-color: #032043;
  }
  &::before, &::after {
    background-color: transparent;
  }
}
.el-pagination{
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .el-pager{
    li{
      font-size: 14px;
      color: #bee9ff !important;
      background-color: transparent !important;
      border: 1px solid #19446c;
      &.active{
        background-color: #19446c !important;
      }
    }
  }
}
</style>
<script setup>
</script>
